import React from "react";
import PortfolioPageAgro from "./PortfolioAgro/PortfolioPageAgro";
import PortfolioProjectAgro from "./PortfolioAgro/PortfolioProjectAgro";
import {Request} from "../Mains/Request/Request";

const PortfolioAgro = () => {
    return (
        <section className="content">
            <PortfolioPageAgro/>
            <PortfolioProjectAgro/>
            <Request />
        </section>
    )
}

export default PortfolioAgro