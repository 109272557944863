import React from "react";
import illustr from "../../../../assets/images/smallIllustr.png"
import "../../../../sass/aboutPage/histoty.sass"
import {useTranslation} from "react-i18next";


const HistoryBody = () => {
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col-lg-5 col-sm ">
                <p className="whiteSubtitle1">{t('little.3')}</p>
                <p className="whiteSixMenu">{t('nimble.3')}</p>
                <p className="whiteSixMenu">{t('why.3')}</p>
                <p className={"whiteSixMenu"}>{t('why.4')}</p>
            </div>
            <div className="col-lg-7 col-sm-12 d-flex align-items-center justify-content-center">
                <img src={illustr} alt="small"/>
            </div>
            <div className="col-sm-12 history-item">
                <p className="whiteSubtitle1">{t('workings.3')}</p>
                <div className="row align-items-baseline">
                    <div className="col-lg-6">
                        <p className="whiteSixMenu">{t('primarily.3')}</p>
                        <p className="whiteSixMenu">{t('individual.3')}</p>
                        {/*<p className="whiteSixMenu">{t('solving.3')}</p>*/}
                        {/*<p className="whiteSixMenu">{t('budget.3')}</p>*/}
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <p className="whiteSixMenu">{t('contract.3')}</p>
                        <p className="whiteSixMenu">{t('unique.3')}</p>
                        {/*<p className="whiteSixMenu">{t('than.3')}</p>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HistoryBody