import React from "react";
import "../../../sass/base/_base.sass"
import agroLogo from "../../../assets/images/logWeb.svg"
import agro from "../../../assets/images/world.png"
import agroTwo from "../../../assets/images/screenword.png"
import agroThree from "../../../assets/images/card.png"
import "../../../sass/Portfolio/portTitan.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import "../../../sass/Portfolio/adaptivePage/adaptiveAgro.sass"
import {useTranslation} from "react-i18next"


const PortfolioPageWeb = () => {

    const { t } = useTranslation()
    return (
        <section className="content">
            <div className="PortfolioPageTitan container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-agro">
                        <img src={agroLogo} alt="agro"/>
                        <p className="whiteSubtitle1">{t('webWorld.12')}</p>
                        <p className="whiteSixMenu">{t('idea.12')}</p>
                        <p className="whiteSixMenu">{t('idea.13')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center first-image">
                        <img src={agro} alt="agroTwo"/>
                    </div>
                </div>
                <div className="row product align-items-center">
                    <div className="col-6 first-tab tablet-img">
                        <img src={agroTwo} alt="agroTwo"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSubtitle1">{t('uniqueness.12')}</p>
                        <p className="whiteSixMenu">{t('trade.2')}</p>
                        <p className="whiteSixMenu">{t('trade.4')}</p>
                        <p className="whiteSixMenu">{t('placement.12')}</p>
                        <img src={agroTwo} alt="agroTwo" className="image-agros"/>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('aimed.2')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('prices.2')}</li>
                            <li className="whiteSevenBody1">{t('distributors.2')}
                            </li>
                            <li className="whiteSevenBody1">{t('Trading.2')}
                            </li>
                            <li className="whiteSevenBody1">{t('stages.2')}
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center secondary-image ">
                        <img src={agroThree} alt="agroThree" className="image-titan"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageWeb