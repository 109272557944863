import React from "react";
import "../../sass/base/fonts.sass"
import "../../sass/Portfolio/ProjectPort.sass"
import error from "../../assets/images/error.png"


const Error = () => {
    return (
       <section className="Error">
           <div className="container error">
               <div className="row">
                   <div className="d-flex align-items-center justify-content-center">
                       <h1 className="whiteOne err" >Error</h1>
                       <h2 className="whiteOne ">404</h2>
                   </div>
                   <div className="col-sm-12 d-flex align-items-center justify-content-center">
                       <img src={error} alt="err" />
                   </div>
               </div>
           </div>
       </section>
    )
}
export default Error