import React from 'react'
// import Form from './RequestForm/RequestForm'
import img from '../../../assets/images/illustr.png'
import {useTranslation} from 'react-i18next';
import "../../../sass/pages/requred.sass"
import "../../../sass/pages/adaptive.sass"
import "../../../sass/base/fonts.sass"
import {
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import Forms from "./RequestForm/RequestForm";
import Sormes from "./RequestForm/formikReact";



export const Request = () => {
    const {t} = useTranslation()

    return (
        <section className="request">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-11 forms">
                        <h2 className="whiteTwo">{t('request.8')}</h2>
                        <p className="ital">{t('team.8')}</p>
                        <GoogleReCaptchaProvider reCaptchaKey="6LeIf1IeAAAAAFkSJqg-sBjv3CMP9H8KfMPS_7h9">
                            {/*<Forms />*/}
                            <Sormes/>
                        </GoogleReCaptchaProvider>
                    </div>
                    <div className="col-lg-6 col-11 dev">
                        <img src={img} alt="img"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
