import React from "react"
import PortfolioProjectAgro from "./PortfolioAgro/PortfolioProjectAgro"
import {Request} from "../Mains/Request/Request"
import PortfolioPageWeb from "./PortfolioWeb/PortfolioPageWeb"

const PortfolioWebWorld = () => {
    return (
        <section className="content">
            <PortfolioPageWeb/>
            <PortfolioProjectAgro/>
            <Request />
        </section>
    )
}

export default PortfolioWebWorld