import React from "react";
import {Request} from "../Mains/Request/Request";
import PortfolioPageYour from "./PortfolioYour/PortfolioPageYour";
import PortfolioProject from "./PortfolioAgro/PortfolioProjectAgro";

const PortfolioYour = () => {
    return (
        <section className="content">
            <PortfolioPageYour/>
            <PortfolioProject/>
            <Request/>
        </section>
    )
}

export default PortfolioYour