import React from "react";
import "../../../sass/base/_base.sass"
import agroLogo from "../../../assets/images/catc.png"
import agro from "../../../assets/images/catch_web.png"
import agroTwo from "../../../assets/images/catch_tab.png"
import agroThree from "../../../assets/images/catch_mob.png"
import "../../../sass/Portfolio/portCoin.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import {useTranslation} from "react-i18next";


const PortfolioPageCoin = () => {
    const { t } = useTranslation();
    return (
        <section className="content">
            <div className="PortfolioPageCoin container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-coin-logo image-agro">
                        <img src={agroLogo} alt="agro"/>
                        <p className="whiteSubtitle1">{t('multi.2')} </p>
                        <p className="whiteSixMenu">{t('security.2')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-end image-coin-web first-image">
                        <img src={agro} alt="agroTwo"/>
                    </div>
                </div>
                <div className="row product align-items-center">
                    <div className="col-6 image-coin-tablet first-tab">
                        <img src={agroTwo} alt="agroTwo"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSixMenu">{t('main.10')}</p>
                        <p className="whiteSixMenu">{t('advantage.10')}</p>
                        <img src={agroTwo} alt="agroTwo" className="image-agros"/>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('Crypto.10')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('one.2')}</li>
                            <li className="whiteSevenBody1">{t('Fast.2')}</li>
                            <li className="whiteSevenBody1">{t('securit.2')}</li>
                            <li className="whiteSevenBody1">{t('charge.2')}</li>
                            <li className="whiteSevenBody1">{t('Earn.2')}</li>

                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center secondary-image">
                        <img src={agroThree} alt="agroThree"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageCoin