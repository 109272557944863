// import * as axios from "axios"



import axios from "axios";

const instance = axios.create({
    baseURL: "https://simplexpage.dev",
})

export const createDataForm = async (contacts: any) => {
    try {
        const request = async (props) => {
            const res = await fetch(`https://simplexpage.dev/backend/contact.php`, {
                withCredentials: true,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                credentials: "same-origin",
                method: "POST",
                body: new URLSearchParams(contacts),
            });
            const data = await res.json();
            if (!res.ok) {
                throw new Error(data);
            }
        };
        if (contacts) {
            await request();
        }
    } catch (e) {
        return e;
    }
}
