import React from "react";
import "../../../sass/base/_base.sass"
import agroLogo from "../../../assets/images/triple_main.svg"
import agro from "../../../assets/images/trip.png"
import agroTwo from "../../../assets/images/trip_teblet.png"
import agroThree from "../../../assets/images/trip_mobile.svg"
import "../../../sass/Portfolio/portTitan.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import "../../../sass/Portfolio/adaptivePage/adaptiveAgro.sass"
import {useTranslation} from "react-i18next";


const PortfolioPageTriple = () => {
    const { t } = useTranslation()
    return (
        <section className="content">
            <div className="PortfolioPageTitan container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-agro">
                        <img src={agroLogo} alt="agro"/>
                        <p className="whiteSubtitle1">{t('Helps.13')}</p>
                        <p className="whiteSixMenu">{t('an.13')}</p>
                        <p className="whiteSixMenu">{t('online.13')}</p>
                        <p className="whiteSixMenu">{t('diamonds.13')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center first-image">
                        <img src={agro} alt="agroTwo"/>
                    </div>
                </div>
                <div className="row product align-items-center">
                    <div className="col-6 first-tab">
                        <img src={agroTwo} alt="agroTwo"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSubtitle1">{t('public.13')}</p>
                        <p className="whiteSixMenu">{t('servicess.13')}</p>
                        <p className="whiteSixMenu">{t('offices.13')}</p>
                        <img src={agroTwo} alt="agroTwo" className="image-agros"/>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('above.13')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('Using.13')}</li>
                            <li className="whiteSevenBody1">{t('Simplicity.13')}</li>
                            <li className="whiteSevenBody1">{t('Exposure.13')}</li>
                            <li className="whiteSevenBody1">{t('Repeat.13')}</li>
                            <li className="whiteSevenBody1">{t('benefits.13')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center secondary-image ">
                        <img src={agroThree} alt="agroThree" className="image-titan"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageTriple