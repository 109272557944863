import React from "react";
import catchCoin from "../../../assets/images/Group 181.svg"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import catg from "../../../assets/images/CatchIllu.png"
import {useTranslation} from "react-i18next";

const RewiewProject = () => {
    const { t } = useTranslation();
    return (
        <div className="row rewiew-body">
            <div className="col-lg-6 col-sm-12">
                <img src={catchCoin} alt="catchCoin" className="coin-image"/>
                <p className="whiteSubtitle1">{t('multi.2')}</p>
                <p className="whiteFiveSubtitle3">{t('security.2')} </p>
                <h3 className="WhiteThree">{t('for.2')}</h3>
                <ul className="d-flex flex-column align-items-start">
                    <li className="whiteSevenBody1">{t('one.2')}</li>
                    <li className="whiteSevenBody1">{t('Fast.2')}</li>
                    <li className="whiteSevenBody1">{t('securit.2')}</li>
                    <li className="whiteSevenBody1">{t('charge.2')}</li>
                    <li className="whiteSevenBody1">{t('Earn.2')}</li>

                </ul>
            </div>
            <div className="col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                <img src={catg} alt="catchCoin"/>
            </div>
        </div>
    )
}

export default RewiewProject