import React from "react";
import {Request} from "../Mains/Request/Request";
import PortfolioPageStockPoint from "./PortfolioStockPointPage/PortfolioPageStockPoint";
import PortfolioProject from "./PortfolioAgro/PortfolioProjectAgro";

const PortfolioStockPoint = () => {
    return (
        <section className="content">
            <PortfolioPageStockPoint/>
            <PortfolioProject/>
            <Request/>
        </section>
    )
}

export default PortfolioStockPoint