import React from "react";
import "../../../sass/base/_base.sass"
import agroLogo from "../../../assets/images/titan.svg"
import agro from "../../../assets/images/titan_main.png"
import agroTwo from "../../../assets/images/VELTA.png"
import agroThree from "../../../assets/images/titan_mobile.png"
import "../../../sass/Portfolio/portTitan.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import "../../../sass/Portfolio/adaptivePage/adaptiveAgro.sass"
import {useTranslation} from "react-i18next";


const PortfolioPageTitan = () => {
    const { t } = useTranslation()
    return (
        <section className="content">
            <div className="PortfolioPageTitan container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-agro">
                        <img src={agroLogo} alt="agro"/>
                        <p className="whiteSubtitle1">{t('era.10')}</p>
                        <p className="whiteSixMenu">{t('companyT.10')}</p>
                        <p className="whiteSixMenu">{t('based.10')}</p>
                        <p className="whiteSixMenu">{t('titanium.10')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center first-image">
                        <img src={agro} alt="agroTwo"/>
                    </div>
                </div>
                <div className="row product align-items-center">
                    <div className="col-6 first-tab">
                        <img src={agroTwo} alt="agroTwo"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSubtitle1">{t('producer.10')}</p>
                        <p className="whiteSixMenu">{t('obtaining.10')}</p>
                        <p className="whiteSixMenu">{t('humanity.10')}</p>
                        <img src={agroTwo} alt="agroTwo" className="image-agros"/>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('fast.10')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('liquid.10')}</li>
                            <li className="whiteSevenBody1">{t('Cl2.10')}</li>
                            <li className="whiteSevenBody1">{t('environment.10')}</li>
                            <li className="whiteSevenBody1">{t('times.10')}</li>
                            <li className="whiteSevenBody1">{t('recycling.10')}</li>
                            <li className="whiteSevenBody1">{t('value.10')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center secondary-image ">
                        <img src={agroThree} alt="agroThree" className="image-titan"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageTitan