import React from "react";
import { NavLink } from "react-router-dom";
import '../../sass/pages/Create.sass';
import { useTranslation } from 'react-i18next';
import "../../sass/base/_base.sass"
import Particles from "react-tsparticles";
import log from '../../assets/images/log.svg'

export const Create = () => {
    const { t } = useTranslation();
    return (
        <section className="Create">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 future">
                        <h1 className="whiteOne">{t('Create.2')}</h1>
                        <p className="whiteSubtitle1">{t('Web.2')}</p>
                        <NavLink to="/contacts" className="bor">{t('Order.2')}</NavLink>
                    </div>
                    <div className="col-xl-5 offset-xl-1 col-lg-6 picture "style={{position: 'relative'}}>
                        {/*<video autoPlay loop muted width="650" src={videomp} className="backvid">*/}
                        {/*    <source src={videomp} type="video/mp4"/>*/}
                        {/*</video>*/}
                        <Particles
                            id={"tsparticles"}
                            options={{
                                fullScreen: { enable: false, zIndex: 0 },
                                detectRetina: false,
                                fpsLimit: 30,
                                interactivity: {
                                    detectsOn: "canvas",
                                    events: {
                                        onClick: {
                                            enable: false,
                                            mode: "push"
                                        },
                                        onDiv: {
                                            elementId: "repulse-div",
                                            enable: false,
                                            mode: "repulse"
                                        },
                                        onHover: {
                                            enable: true,
                                            mode: "bubble",
                                            parallax: {
                                                enable: false,
                                                force: 2,
                                                smooth: 10
                                            }
                                        },
                                        resize: true
                                    },
                                    modes: {
                                        bubble: {
                                            distance: 40,
                                            duration: 2,
                                            opacity: 8,
                                            size: 6,
                                            speed: 3
                                        },
                                        connect: {
                                            distance: 200,
                                            lineLinked: {
                                                opacity: 0.5
                                            },
                                            radius: 160
                                        },
                                        grab: {
                                            distance: 800,
                                            lineLinked: {
                                                opacity: 1
                                            }
                                        },
                                        push: {
                                            quantity: 4
                                        },
                                        remove: {
                                            quantity: 2
                                        },
                                        repulse: {
                                            distance: 400,
                                            duration: 0.4
                                        },
                                        slow: {
                                            active: false,
                                            radius: 0,
                                            factor: 2
                                        }
                                    }
                                },
                                particles: {
                                    color: {
                                        value: ["#8300C1", "#F2D7FF", "#C882FF", "#E066FF"]
                                    },
                                    lineLinked: {
                                        blink: false,
                                        color: "random",
                                        consent: false,
                                        distance: 60,
                                        enable: true,
                                        opacity: 0.8,
                                        width: 2
                                    },
                                    move: {
                                        attract: {
                                            enable: false,
                                            rotate: {
                                                x: 600,
                                                y: 1200
                                            }
                                        },
                                        bounce: false,
                                        direction: "none",
                                        enable: true,
                                        outMode: "bounce",
                                        random: false,
                                        speed: .5,
                                        straight: false
                                    },
                                    number: {
                                        density: {
                                            enable: false,
                                            area: 2000
                                        },
                                        limit: 0,
                                        value: 200
                                    },
                                    opacity: {
                                        animation: {
                                            enable: true,
                                            minimumValue: 0.3,
                                            speed: 2,
                                            sync: false
                                        },
                                        random: false,
                                        value: 0.8
                                    },
                                    shape: {
                                        character: {
                                            fill: false,
                                            font: "Verdana",
                                            style: "",
                                            value: "*",
                                            weight: "400"
                                        },
                                        image: {
                                            height: 100,
                                            replaceColor: true,
                                            src: "https://cdn.matteobruni.it/images/particles/github.svg",
                                            width: 100
                                        },
                                        polygon: {
                                            sides: 5
                                        },
                                        stroke: {
                                            color: "#000000",
                                            width: 0
                                        },
                                        type: "circle"
                                    },
                                    size: {
                                        animation: {
                                            enable: false,
                                            minimumValue: 0.1,
                                            speed: 40,
                                            sync: false
                                        },
                                        random: true,
                                        value: 1
                                    }
                                },
                                polygon: {
                                    draw: {
                                        enable: false,
                                        lineColor: "#8300C1",
                                        lineWidth: 1
                                    },
                                    enable: true,
                                    move: {
                                        radius: 5
                                    },
                                    position: {
                                        x: 53,
                                        y: 75
                                    },
                                    inlineArrangement: "equidistant",
                                    scale: 1.2,
                                    type: "inline",
                                    image: {log},
                                    data: {
                                        path:
                                            "M11 171.441H105.242C105.242 114.894 148.739 103.749 170.487 105.245V11C45.128 19.0782 11.9294 121.327 11 171.441Z M137.586 331.883V238.198C145.616 238.198 162.866 232.214 170.487 229.223C180.339 224.361 200.935 204.876 204.504 165.831C208.072 126.787 225.322 90.0987 233.501 76.6351C262.945 27.7173 332.762 12.496 363.991 11V105.245C303.207 114.782 301.424 147.682 293.727 196.686C271.421 309.78 180.339 333.939 137.586 331.883Z M393.546 171.441C389.085 229.335 346.332 240.068 325.513 238.198V331.883C449.534 325.599 483.513 222.304 485 171.441H393.546Z ",
                                        size: {
                                                width: 521,
                                                height: 521,
                                        }
                                    }
                                },
                                background: {
                                    color: "transparent",
                                    image: "",
                                    position: "50% 50%",
                                    repeat: "no-repeat",
                                    size: "cover"
                                }
                            }}
                        />
                    </div>
                </div>
            </div>  
        </section>
    )
}