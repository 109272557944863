import React, {useState} from "react";
import {Formik, Field} from "formik"
import * as yup from "yup"
import {createDataForm} from "../../../../API/api";
import {useTranslation} from "react-i18next";
import Modal from "../../../../Component/Modal/modal";
import {
    useGoogleReCaptcha,
    GoogleReCaptcha
} from "react-google-recaptcha-v3";


const Sormes = () => {
    const {t} = useTranslation()

    const phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/

    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleSubmit = async data => {
        try {
            const newToken = executeRecaptcha("MS_Pyme_DatosEmpresa");
            console.log({data, newToken});
        } catch (err) {
            throw new Error("Token error");
        }
    };

    const valid = yup.object().shape({
        name: yup.string().required('Required').min(2, 'slow'),
        phone: yup.string().required('Required').matches(phoneRegExp, 'Is not valid'),
        details: yup.string().required('Required').min(10, 'slow')
    })

    const onSubmit = (formData, onSubmitProps) => {
        createDataForm(formData);
    }
    const [modalActive, setModalActive] = useState(false)
    return (
        <div>

            <Formik initialValues={{
                name: '',
                phone: '',
                details: '',
                service: ''
            }}
                    validateOnBlur
                    // onSubmit={(values => {
                    //     onSubmit(values)
                    // })}
                    onSubmit={(values, {resetForm}) => {
                        onSubmit(values)
                        resetForm({values: ''})
                        setModalActive(true)
                    }}
                    validationSchema={valid}
            >
                {({values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty, resetForm}) => (
                    <>
                        <GoogleReCaptcha onVerify={t => console.log({t})}/>
                        <form method="POST" >
                            <div className={'d-flex flex-column'}>
                                <div className={'required d-flex formControl'}>
                                    <label htmlFor={'service'} className={'whiteSubtitle1'}>{t('services.8')}</label>
                                    <Field id="select_type" name={"service"} component={"select"}>
                                        <option value="Select type of your idea">{t('your.8')}</option>
                                        <option value="development">{t('WebD.8')}</option>
                                        <option value="Design">{t('des.8')}</option>
                                        <option value="engineering">{t('sys.8')}</option>
                                        <option value="Marketing">{t('mark.8')}</option>
                                        <option value="Mobile">{t('mob.8')}</option>
                                    </Field>
                                </div>
                                <div
                                    className={touched.name && errors.name ? 'required d-flex formControl error' : 'required d-flex formControl'}>
                                    <label htmlFor={'name'} className={'whiteSubtitle1'}>Name</label>
                                    <Field name={'name'}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.name}
                                           type={'text'}
                                           style={{color: 'white'}}
                                           placeholder={t('names.2')}>
                                    </Field>
                                </div>
                                <div
                                    className={touched.phone && errors.phone ? 'required d-flex formControl error' : 'required d-flex formControl'}>
                                    <label htmlFor={'phone'} className={'whiteSubtitle1'}>Phone</label>
                                    <Field name={'phone'}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.phone}
                                           placeholder={"+ 38 (000) XXX XXX XX"}
                                           style={{color: 'white'}}
                                           type={'text'}>
                                    </Field>
                                </div>
                                <div
                                    className={touched.details && errors.details ? 'required d-flex formControl error' : 'required d-flex formControl'}>
                                    <label htmlFor={'details'} className={'whiteSubtitle1'}>Details</label>
                                    <Field name={'details'}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.details}
                                           type={'textarea'}
                                           placeholder={t('describe.2')}
                                           component={'textarea'}>
                                    </Field>
                                </div>
                                <div className="w-100">
                                    <button className="lener" disabled={!isValid} onClick={handleSubmit}
                                            type={'submit'}>{t('send.8')}</button>
                                    <Modal active={modalActive} setActive={setModalActive}></Modal>
                                </div>
                            </div>
                        </form>
                    </>
                )
                }
            </Formik>

        </div>
    )
}

export default Sormes