import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import {Field, reduxForm} from "redux-form";
import "../../../../sass/pages/requred.sass"
import "../../../../sass/pages/adaptive.sass"
import "../../../../sass/base/fonts.sass"
import Modal from "../../../../Component/Modal/modal";
import {maxLengthCreator, required} from "../../../../Component/utils/validators/validator";
import {inputName, inputPhone, TextArea} from "../../../../Component/utils/FormControls/FormControls";
import {createDataForm} from "../../../../API/api";

import {
    useGoogleReCaptcha,
    GoogleReCaptcha
} from "react-google-recaptcha-v3";


const maxLength15 = maxLengthCreator(15)



const RequestForm = (props) => {

    const { t } = useTranslation()
    const [modalActive, setModalActive] = useState(false)


    const [name, setName] = useState( '')
    const [phone, setPhone] = useState('')
    const [details, setDetails] = useState('')

    const [nameDirty, setNameDirty] = useState(false)
    const [phoneDirty, setPhoneDirty] = useState(false)
    const [detailsDirty, setDetailsDirty] = useState(false)

    const [nameError, setNameError] = useState('*Field is required')
    const [phoneError, setPhoneError] = useState('*Field is required')
    const [detailsError, setDetailsError] = useState('*Field is required')

    const [formValid, setFormValid] = useState(false)

    useEffect(() => {
        if(nameError || phoneError || detailsError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }

    }, [nameError, phoneError, detailsError])

    const phoneHandler = (e) => {
        setPhone(e.target.value)
        const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
        if (!re.test(String(e.target.value).toLowerCase())) {
            setPhoneError('*Invalid phone number')
        } else  {
            setPhoneError('')
        }
    }
    const nameHandler = (e) => {
        setName(e.target.value)
        if (e.target.value.length < 1 || e.target.value.length > 20) {
            setNameError('')
        } else {
            setNameError('')
        }
    }

    const detailsHandler = (e) => {
        setDetails(e.target.value)
        if (e.target.value.length < 1 || e.target.value.length > 900) {
            setDetailsError('')
        } else {
            setDetailsError('')
        }
    }

    const blurHandler = (e) => {

        switch (e.target.name) {
            case 'name' :
                setNameDirty(true)
                break
            case 'phone' :
                setPhoneDirty(true)
                break
            case 'details' :
                setDetailsDirty(true)
                break
        }
    }
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async data => {
        try {
            const newToken = executeRecaptcha("MS_Pyme_DatosEmpresa");
            console.log({ data, newToken });
        } catch (err) {
            throw new Error("Token error");
        }
    };
    return (
          <>
            <GoogleReCaptcha onVerify={t => console.log({ t })} />
            <form method="POST"  onSubmit={props.handleSubmit} onReset={props.onReset} >
                <div className={'required d-flex'}>
                    <p className="whiteSubtitle1">{t('services.8')}</p>
                    <Field id="select_type" name={"service"} component={"select"} >
                        <option value="Select type of your idea">{t('your.8')}</option>
                        <option value="development">{t('WebD.8')}</option>
                        <option value="Design">{t('des.8')}</option>
                        <option value="engineering">{t('sys.8')}</option>
                        <option value="Marketing">{t('mark.8')}</option>
                        <option value="Mobile">{t('mob.8')}</option>
                    </Field>
                </div>
                <div >
                    <Field placeholder={t('names.2')} value={name}  name={'name'} onChange={e => nameHandler(e)} onBlur={e => blurHandler(e)}  component={inputName} maxLength={30}  validate={[required]}  />
                    {(nameDirty && nameError) && <div style={{color: 'red'}}>{nameError}</div>}
                </div>
                <div >
                    <Field placeholder={"+ 38 (000) XXX XXX XX"} value={phone}  onChange={e => phoneHandler(e)}  onBlur={e => blurHandler(e)}  name={'phone'} component={inputPhone} maxLength={15} validate={[required, maxLength15]}  />
                    {(phoneDirty && phoneError) && <div style={{color: 'red'}}>{phoneError}</div>}
                </div>x
                <div >
                    <Field placeholder={t('describe.2')}  maxLength={400} value={details}  onChange={e => detailsHandler(e)}  onBlur={e => blurHandler(e)}  name={'details'} component={TextArea} validate={[required]}/>
                    {(detailsDirty && detailsError) && <div style={{color: 'red'}}>{detailsError}</div>}
                </div>
                <button className="lener" type="submit" onClick={() => {handleSubmit(); setModalActive(true)}}   disabled={!formValid}  >{t('send.8')}</button>
                <Modal active={modalActive} setActive={setModalActive}></Modal>
            </form>
          </>
    )
}


const RequestReduxForm = reduxForm({
    form: 'contacts',
})(RequestForm)

const Form = (props) => {

    const onSubmit = (formData) => {
        return createDataForm(formData)
    }

    return<div>
        <RequestReduxForm onSubmit={onSubmit} />
    </div>

}

export default Form

