import React from "react";
import {Request} from "../Mains/Request/Request";
import PortfolioPageCoin from "./PortfolioCoin/PortfolioPageCoin";
import PortfolioProject from "./PortfolioAgro/PortfolioProjectAgro";

const PortfolioCoin = () => {
    return (
        <section className="content">
            <PortfolioPageCoin/>
            <PortfolioProject/>
            <Request/>
        </section>
    )
}

export default PortfolioCoin