import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import {Footer} from './Component/FooterComponent/Footer'
import {Header} from './Component/NavBarComponent/Header'
import {About} from './pages/About/About'
import {MainPage} from './pages/Mains/MainPage'
import "./sass/base/_base.sass"
import Technologies from "./pages/Technologies/Technologies"
import Contacts from "./pages/Contacts/Contacts"
import VacanciesPage from "./pages/Vacancies/VacanciesPage"
import Portfolio from "./pages/Portfolio/Portfolio"
import PortfolioAgro from "./pages/PortfolioPage/PortfolioAgro"
import PortfolioBKKFU from "./pages/PortfolioPage/PortfolioBKKFU"
import PortfolioCoin from "./pages/PortfolioPage/PortfolioCoin"
import PortfolioTitan from "./pages/PortfolioPage/PortfolioTitan"
import PortfolioYour from "./pages/PortfolioPage/PortfolioYour"
import PortfolioStockPoint from "./pages/PortfolioPage/PortfolioStockPoint"
import PortfolioStream from "./pages/PortfolioPage/PortfolioStream"
import ScrollToTop from "./ScrollTop"
import PortfolioWebWorld from "./pages/PortfolioPage/PortfolioWebWorld"
import PortfolioTriple from "./pages/PortfolioPage/PortfolioTriple";
import PortfolioPartner from "./pages/PortfolioPage/PortfolioPartner";
import Error from "./pages/Error/error";


const App = () => {



    return (
        <BrowserRouter >
            <ScrollToTop/>
            <Header/>
            <Switch>
                <Route path={'/'} exact
                       render={() => <DocumentTitle title='SimplexPage'><MainPage/></DocumentTitle>}/>
                <Route path={'/about'}
                       render={() => <DocumentTitle title='SimplexPage-About'><About/></DocumentTitle>}/>
                <Route path={'/technologies'} render={() => <DocumentTitle
                    title='SimplexPage-Technologies'><Technologies/></DocumentTitle>}/>
                <Route path={'/contacts'} render={() => <DocumentTitle
                    title='SimplexPage-Contacts'><Contacts/></DocumentTitle>}/>
                <Route path={'/vacancies'} render={() => <DocumentTitle
                    title='SimplexPage-Vacancies'><VacanciesPage/></DocumentTitle>}/>
                <Route path={'/portfolio'}
                       render={() => <DocumentTitle
                           title='SimplexPage-Portfolio'><Portfolio/></DocumentTitle>}/>
                <Route path={'/agroline'} render={() => <DocumentTitle
                    title='SimplexPage-AgroExchange'><PortfolioAgro/></DocumentTitle>}/>
                <Route path={'/bkkfu'}
                       render={() => <DocumentTitle
                           title='SimplexPage-BKKFU'><PortfolioBKKFU/></DocumentTitle>}/>
                <Route path={'/catchcoin'} render={() => <DocumentTitle
                    title='SimplexPage-CatchCoin'><PortfolioCoin/></DocumentTitle>}/>
                <Route path={'/fightliving'}
                       render={() => <DocumentTitle
                           title='SimplexPage-Fightliving'><PortfolioStream/></DocumentTitle>}/>
                <Route path={'/titanera'}
                       render={() => <DocumentTitle
                           title='SimplexPage-Titan'><PortfolioTitan/></DocumentTitle>}/>
                <Route path={'/worklab'}
                       render={() => <DocumentTitle
                           title='SimplexPage-WorkLab'><PortfolioYour/></DocumentTitle>}/>
                <Route path={'/stockpoint'} render={() => <DocumentTitle
                    title='SimplexPage-StockPoint'><PortfolioStockPoint/></DocumentTitle>}/>
                <Route path={'/webworld'} render={() => <DocumentTitle
                    title='SimplexPage-WebWorld'><PortfolioWebWorld/></DocumentTitle>}/>
                <Route path={'/tripleex'} render={() => <DocumentTitle
                    title='SimplexPage-TripleEx'><PortfolioTriple/></DocumentTitle>}/>
                <Route path={'/tripleextoken'} render={() => <DocumentTitle
                    title='SimplexPage-TripleExToken'><PortfolioPartner/></DocumentTitle>}/>
                <Route path={'/avatar'} render={() => <DocumentTitle
                    title='SimplexPage-Avatar'><PortfolioPartner/></DocumentTitle>}/>
                <Route path="" render={() => <DocumentTitle title='404'><Error/></DocumentTitle>}/>
            </Switch>
            <Footer/>
        </BrowserRouter>
    )
}

export default App
