import React from "react"
import {NavLink} from "react-router-dom"
import logo from "../../assets/images/log.svg"
import {useTranslation} from "react-i18next"
import './lang.sass'
// import {Lang} from "./lang"


export const Menu = ({active, setActive}) => {
    // const [modalActive, setModalActive] = useState(false)
    const { i18n } = useTranslation()
    function handleClick(lang) {
        i18n.changeLanguage(lang)
    }
    const navRef = React.useRef(null)
    const navRef2 = React.useRef(null)
    const navRef3 = React.useRef(null)
    const onAddClick = () => {
        navRef.current.classList.add("active")
    }

    const onRemoveClick = (e) => {
        navRef2.current.classList.remove("active")
        navRef3.current.classList.remove("active")
    }
    const onAddClick2 = (e) => {
        navRef2.current.classList.add("active")
    }
    const onRemoveClick2 = (e) => {
        navRef.current.classList.remove("active")
        navRef3.current.classList.remove("active")
    }
    const onAddClick3 = (e) => {
        navRef3.current.classList.add("active")
    }

    const onRemoveClick3 = (e) => {
        navRef.current.classList.remove("active")
        navRef2.current.classList.remove("active")
    }


    const {t} = useTranslation()


    return (
        <header>
            <nav className="navbar ">
                <NavLink to="/" className="logo" onClick={() => setActive(false)}><img src={logo} alt="logo"/></NavLink>
                <ul  className={`navbarNav ${active && "active"}`} >
                    <li><NavLink to="/about" className={active ? "whiteElevenButton1 active" : "whiteElevenButton1"}
                                 onClick={() => setActive(false)}>{t('Abou.1')}</NavLink></li>
                    <li><NavLink to="/technologies"
                                 className={active ? "whiteElevenButton1 active" : "whiteElevenButton1"} onClick={() => setActive(false)}>{t('Techno.1')}</NavLink>
                    </li>
                    <li><NavLink to="/portfolio"
                                 className={active ? "whiteElevenButton1 active" : "whiteElevenButton1"} onClick={() => setActive(false)}>{t('Portf.1')}</NavLink>
                    </li>
                    <li><NavLink to="/vacancies"
                                 className={active ? "whiteElevenButton1 active" : "whiteElevenButton1"} onClick={() => setActive(false)}>{t('Vacanc.1')}</NavLink>
                    </li>
                    <li><NavLink to="/contacts"
                                 className={active ? "whiteElevenButton1 active" : "whiteElevenButton1"} onClick={() => setActive(false)}>{t('Conta.1')}</NavLink>
                    </li>
                </ul>
                <div className={`lang-body ${active && "active"}`}>
                    <button ref={navRef} className="nav-linkd active"   onClick={()=> {handleClick('en'); setActive(false); onAddClick(); onRemoveClick();}}>En</button>
                    <button ref={navRef2} className="nav-linkd"  onClick={()=>{handleClick('ru'); onAddClick2(); onRemoveClick2()}}>Rus</button>
                    <button ref={navRef3} className="nav-linkd"  onClick={()=> {handleClick('ua'); onAddClick3(); onRemoveClick3()}}>Ua</button>
                </div>
                {/*<Lang active={modalActive} setActive={setModalActive} />*/}
                <div className={active ? 'burger active' : 'burger'} onClick={() => setActive(!active)}>
                    <div className="hamburgerIcon" ></div>
                </div>
            </nav>
        </header>

    )
}