import React from "react"
import "../../../sass/base/_base.sass"
import agroLogo from "../../../assets/images/pure.png"
import agro from "../../../assets/images/stockPoint.png"
import agroTwo from "../../../assets/images/stock-2.png"
import agroThree from "../../../assets/images/stock-1.png"
import "../../../sass/Portfolio/portStock.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import {useTranslation} from "react-i18next";

const PortfolioPageStockPoint = () => {
    const { t } = useTranslation()
    return (
        <section className="content">
            <div className="PortfolioPageStockPoint container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-agro">
                        <img src={agroLogo} alt="agro"/>
                        <p className="whiteSubtitle1">{t('stock_t.12')}</p>
                        <p className="whiteSixMenu">{t('stock_p1.12')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center first-image">
                        <img src={agro} alt="agroTwo"/>
                    </div>
                </div>
                <div className="row product align-items-center">
                    <div className="col-6 first-tab">
                        <img src={agroTwo} alt="agroTwo"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSubtitle1">{t('stock_st1.12')}</p>
                        <p className="whiteSixMenu">{t('stock_p3.12')}</p>
                        <p className="whiteSixMenu">{t('stock_p4.12')}</p>
                        <img src={agroTwo} alt="agroTwo" className="image-agros"/>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('stock_st2.12')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('stock_li1.12')}</li>
                            <li className="whiteSevenBody1">{t('stock_li2.12')}</li>
                            <li className="whiteSevenBody1">{t('stock_li3.12')}</li>
                            <li className="whiteSevenBody1">{t('stock_li4.12')}</li>
                            <li className="whiteSevenBody1">{t('stock_li5.12')}</li>
                            <li className="whiteSevenBody1">{t('stock_li6.12')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center">
                        <img src={agroThree} alt="agroThree"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageStockPoint