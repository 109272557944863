import React from "react";
import TeamBody from "./TeamBody/TeamBody";
import "../../../sass/base/fonts.sass"
import "../../../sass/aboutPage/ourTeam.sass"
import {useTranslation} from "react-i18next";


const OurTeamPage = () => {
    const { t } = useTranslation();
    return (
        <div className="Team">
            <div className="container-fluid">
                <h2 className="whiteTwo text-center">{t('tea.3')}</h2>
                <p className="team-title text-center">{t('close.3')}</p>
                <TeamBody/>
            </div>
        </div>

    )
}

export default OurTeamPage