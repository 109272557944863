import {NavLink} from "react-router-dom"
import "../../sass/pages/Footer.sass"
import "../../sass/base/fonts.sass"
import simplex from '../../assets/images/log.svg'
import "../../sass/pages/adaptive.sass"
import React from "react"


export const Footer = () => {

    return <section className="Footer">
        <div className="container-fluid">
            <div className="row footer-item justify-content-between ">
                <div className="col-lg-3 col-12 simplex d-flex align-items-center">
                    <NavLink to={'/'}><img src={simplex} alt="mark" /></NavLink>
                </div>
                <div className="col-lg-3 col-sm-5 contact d-flex align-items-center">
                    <div className="d-flex">
                        <a href="https://www.linkedin.com/company/simplexpage" target={"_blank"}><span className={'social linkedin'}></span></a>
                        <a href="https://www.facebook.com/SimplexPage-104116082093215/" target={"_blank"}><span className={'social facebook'}></span></a>
                        <a href="https://www.instagram.com/simplexpage/" target={"_blank"}><span className={'social instagram'}></span></a>
                        <a href="https://twitter.com/simplex_page" target={"_blank"} ><span className={'social twitter'}></span></a>
                        <a href="https://www.behance.net/simplexpage" target={"_blank"}><span className={'social behance'}></span></a>
                    </div>
                </div>
                <div className="col-lg-3 col-12 contact d-flex flex-column  justify-content-center">
                    <p className="whiteElevenButton1"><b>Email:</b><a href="mailto:support@simplexpage.dev"> support@simplexpage.dev</a></p>
                    <p className="whiteElevenButton1"><b>Phone:</b> +38 (099) 13 164 15</p>
                </div>
            </div>
            <div className="row justify-content-between align-items-center footer-bot">
                <div className="col-12 d-flex justify-content-center">
                    <p className="whiteElevenButton1 text-start bottom-text">©{new Date().getFullYear()}, SimplexPage</p>
                </div>
            </div>
        </div>
    </section>
}