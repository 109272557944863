import React, {useState} from "react";
import "../../../sass/base/fonts.sass"
import "../../../sass/pages/VacanciesPage.sass"
import VacanciesList from "./VacanciesList";
import {useTranslation} from "react-i18next";
import {Tab, Tabs} from "react-bootstrap";
import HeadVacancies from "../../Mains/Vacancies/VacanciesList/VacancieFront/HeadVacancies";
import BodyVacFront from "./BodyVacFront";



const VacanciesReact = (props ) => {


    const [key, setKey] = useState('all');
    const { t } = useTranslation()
    return(
        <div className="row flex-wrap ">
            <h2 className="whiteTwo text-center">{t('Vacanc.1')}</h2>
            <p className="whiteSubtitle1 cannot-type text-center">{t('offer.1')}</p>
            <div className="vacancies-list d-flex justify-content-start flex-wrap">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="all" title={t('All.3')}>
                        <VacanciesList vacancies={props.store}/>
                    </Tab>
                    <Tab eventKey="backend" title={t('backend.3')}>
                        <div className="row flex-wrap vacancies-dev">
                            <div className={props.store.state.blockIm[2].col}>
                                <img src={props.store.state.blockIm[2].src} alt="ill"/>
                            </div>
                            <div className={props.store.state.blockIm[2].coles} key={props.store.state.blockIm[2].id}>
                                <HeadVacancies vacancies={props.vacancies} name={props.store.state.blockIm[2].name} list={props.store.state.blockIm[2].list}/>
                                <BodyVacFront vacation={props.vacancies} key={props.store.state.blockIm[2].id} message={props.store.state.blockIm[2].message} least={props.store.state.blockIm[2].least} tool={props.store.state.blockIm[2].tool} know={props.store.state.blockIm[2].know} exp={props.store.state.blockIm[2].exp} gup={props.store.state.blockIm[2].gup} und={props.store.state.blockIm[2].und} plus={props.store.state.blockIm[2].plus}/>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="frontend" title={t('front.3')}>
                        <div className="row flex-wrap vacancies-dev ">
                            <div className={props.store.state.blockIm[0].col}>
                                <img src={props.store.state.blockIm[2].src} alt="ill"/>
                            </div>
                            <div className={props.store.state.blockIm[0].coles} key={props.store.state.blockIm[0].id}>
                                <HeadVacancies vacancies={props.vacancies} name={props.store.state.blockIm[0].name} list={props.store.state.blockIm[0].list}/>
                                <BodyVacFront vacation={props.vacancies} key={props.store.state.blockIm[0].id} message={props.store.state.blockIm[0].message} least={props.store.state.blockIm[0].least} tool={props.store.state.blockIm[0].tool} know={props.store.state.blockIm[0].know} exp={props.store.state.blockIm[0].exp} gup={props.store.state.blockIm[0].gup} und={props.store.state.blockIm[0].und} plus={props.store.state.blockIm[0].plus}/>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="qa" title="UI/UX Designer" >
                        <div className="row flex-wrap vacancies-dev ">
                            <div className={props.store.state.blockIm[1].col}>
                                <img src={props.store.state.blockIm[1].src} alt="ill"/>
                            </div>
                            <div className={props.store.state.blockIm[1].coles} key={props.store.state.blockIm[1].id}>
                                <HeadVacancies vacancies={props.vacancies} name={props.store.state.blockIm[1].name} list={props.store.state.blockIm[1].list}/>
                                <BodyVacFront vacation={props.vacancies} key={props.store.state.blockIm[1].id} message={props.store.state.blockIm[1].message} least={props.store.state.blockIm[1].least} tool={props.store.state.blockIm[1].tool} know={props.store.state.blockIm[1].know} exp={props.store.state.blockIm[1].exp} gup={props.store.state.blockIm[1].gup} und={props.store.state.blockIm[1].und} plus={props.store.state.blockIm[1].plus}/>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="ios" title="DevOps">
                        <div className="row flex-wrap vacancies-dev">
                            <div className={props.store.state.blockIm[3].col}>
                                <img src={props.store.state.blockIm[3].src} alt="ill"/>
                            </div>
                            <div className={props.store.state.blockIm[3].coles} key={props.store.state.blockIm[3].id}>
                                <HeadVacancies vacancies={props.vacancies} name={props.store.state.blockIm[3].name} list={props.store.state.blockIm[3].list}/>
                                <BodyVacFront vacation={props.vacancies} key={props.store.state.blockIm[3].id} message={props.store.state.blockIm[3].message} least={props.store.state.blockIm[3].least} tool={props.store.state.blockIm[3].tool} know={props.store.state.blockIm[3].know} exp={props.store.state.blockIm[3].exp} gup={props.store.state.blockIm[3].gup} und={props.store.state.blockIm[3].und} plus={props.store.state.blockIm[3].plus}/>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}
export default VacanciesReact