import React from "react";
import {Request} from "../Mains/Request/Request";
import PortfolioPageBKKFU from "./PortfolioBKKFU/PortfolioPageBKKFU";
import PortfolioProject from "./PortfolioAgro/PortfolioProjectAgro";
import "../../sass/Portfolio/portBkff.sass"

const PortfolioAgro = () => {
    return (
        <section className="content">
            <PortfolioPageBKKFU/>
            <PortfolioProject/>
            <Request/>
        </section>
    )
}

export default PortfolioAgro