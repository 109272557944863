import React from "react";
import "../../../sass/base/fonts.sass"



const BodyVacFront = (props) => {

    return(
        <div className="vacancies-text">
            <p className="whiteSevenBody1 cannot-body-text" >{props.message}</p>
            <p className="whiteEightButton2">Required skills</p>
            <p className="whiteElevenButton1">{props.least}</p>
            <p className="whiteElevenButton1">{props.tool}</p>
            <p className="whiteElevenButton1">{props.know}</p>
            <p className="whiteElevenButton1">{props.exp}</p>
            <p className="whiteElevenButton1">{props.gup}</p>
            <p className="whiteElevenButton1">{props.und}</p>
            <p className="whiteEightButton2">Will be a plus</p>
            <p className="whiteElevenButton1">{props.plus}</p>
        </div>
    )
}
export default BodyVacFront