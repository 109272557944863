import React from 'react'
import RewiewProject from "./Rewiew/RewiewProject";
import RewiewProjectTwo from "./Rewiew/RewiewProjectTwo";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";





export const Rewiew = () => {
    const { t } = useTranslation();
    return (
        <section className="rewiew">
            <div className="container-fluid">
                <h2 className="whiteTwo text-center">{t('Last.2')}</h2>
                <RewiewProject/>
                <RewiewProjectTwo/>
                <div className="text-center clents"><NavLink to={'/portfolio'} className="bor ">{t('Allp.2')}</NavLink></div>
                <h2 className="whiteTwo text-center">{t('Clients.2')}</h2>
                {/*<p className="whiteSubtitle2 text-center">{t('whom.2')}</p>*/}
            </div>
        </section>
    )
}