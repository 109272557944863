import React from "react";
import {Col, Row} from "react-bootstrap";



const TabSecond = () => {

    return(
        <Row className="justify-content-center pt-4">
            <h3 className="">Designing Services</h3>
            <Col md={4} sm={6} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">User Interfaces Design</li>
                    <li className="whiteFiveSubtitle3">User Experience Design</li>
                    <li className="whiteFiveSubtitle3">Social media design</li>
                </ul>

            </Col>
            <Col md={4} sm={6} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">Logo & Branding</li>
                    <li className="whiteFiveSubtitle3">Art & Illustration</li>
                    <li className="whiteFiveSubtitle3">Web Design Services</li>
                </ul>

            </Col>
            <Col md={4} sm={12} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">Print Design Services</li>
                    <li className="whiteFiveSubtitle3">Graphic Design Services</li>
                    <li className="whiteFiveSubtitle3">Product & Merchandise Design Services</li>
                </ul>
            </Col>
        </Row>
    )
}

export default TabSecond