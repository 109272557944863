import React, {useState} from 'react'
import "../../sass/pages/Header.sass"
import "../../sass/pages/adaptive.sass"
import {Menu} from "./menu";



export const Header = (props) => {

    const [modalActive, setModalActive] = useState(false)

    return (
            <div className="Header container-fluid pt-4 congritulation">
                <Menu  active={modalActive} setActive={setModalActive}/>
            </div>
    )
}