import React from "react";
import "../../../sass/base/fonts.sass"
import {useTranslation} from "react-i18next"
import "../../../sass/pages/adaptiveContacts.sass"

const ContactsConnect = () => {
    const { t } = useTranslation()

    return (
        <div className="row">
            <div className="col">
                <div className="row contact-top">
                    <div className="first-container col-xl-5 col-lg-5 col-sm-12 d-flex justify-content-center">
                        <div className={"contact-tab d-flex flex-column justify-content-center flex-lg-fill"}>
                            <p className="whiteSubtitle1">{t('hot.10')}</p>
                            <h2 className="whiteTwo">+38 099 131 64 15 </h2>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-sm-12 d-flex justify-content-center">
                        <div className={"contact-tabs d-flex flex-column justify-content-center flex-lg-fill"}>
                            <p className="whiteSubtitle1">{t('media.3')}</p>
                            <div className="soc-media d-flex flex-sm-wrap flex-lg-nowrap justify-content-between">
                                <a href="https://www.linkedin.com/company/simplexpage" target={"_blank"}><span className={'social linkedin'}/></a>
                                <a href="https://www.facebook.com/SimplexPage-104116082093215/" target={"_blank"}><span className={'social facebook'}/></a>
                                <a href="https://www.instagram.com/simplexpage/" target={"_blank"}><span className={'social instagram'}/></a>
                                <a href="https://twitter.com/simplex_page" target={"_blank"}><span className={'social twitter'}/></a>
                                <a href="https://www.behance.net/simplexpage" target={"_blank"}><span className={'social behance'}></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row contact-bottom d-flex">
                    <div className="first-container col-xl-5 col-lg-5 col-sm-12 d-flex justify-content-center">
                        <div className={"contact-tab contact-two d-flex flex-column justify-content-center flex-lg-fill"}>
                            <p className="whiteSubtitle1">{t('address.10')}</p>
                            <h2 className="whiteTwo">{t('vul.10')}</h2>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-sm-12 d-flex justify-content-center">
                       <div className={"contact-tab contact-two contact-width d-flex flex-column justify-content-center flex-lg-fill"}>
                           <p className="whiteSubtitle1">{t('email.10')}</p>
                           <h2 className="whiteTwo">support@simplexpage.dev</h2>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactsConnect