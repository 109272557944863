import React from "react";
import "../../../sass/base/fonts.sass"
import pictures from "../../../assets/images/portIll.png"
import "../../../sass/Portfolio/portFolio.sass"
import {useTranslation} from "react-i18next";


const PortfolioDemo = () => {
    const { t } = useTranslation()
    return (
        <div className="row">
            <div className="col-lg-6 col-sm d-flex flex-column justify-content-center portfolio-text">
                <h2 className="whiteTwo">{t('Portf.1')}</h2>
                <p className="whiteSubtitle2">{t('demonstrates.2')}</p>
            </div>
            <div className="col-lg-6 col-sm-12 d-flex align-items-center justify-content-center portfolio-image">
                <img src={pictures} alt="pictures" />
            </div>
        </div>
    )
}
export default PortfolioDemo