import React, {useState} from "react";
import "../../sass/modules/modal.sass"
import '../../sass/pages/Create.sass';
import img from '../../assets/images/modal_img.png'
import {useTranslation} from "react-i18next";



const Modal = ({active, setActive}) => {

    const { t } = useTranslation();

    return (
        <div className={active ? "modals active" : "modals"} onClick={() => setActive(false)} >
            <div className={active ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
                <div className={'row modal-body'}>
                    <div className="d-flex flex-column col-sm-7 col-12 modal_title">
                        <h2 className={'whiteTwo'}>{t('Thank.13')}</h2>
                        <p className={'whiteSubtitle2'}>{t('managers.13')}</p>
                        <a  className={'bor mt-3'} onClick={() => setActive(false)}>{t('Back.13')}</a>
                    </div>
                    <div className="col-sm-5 col-12 d-flex align-items-center justify-content-center "><img src={img} alt={'problemo'}/> </div>
                </div>
            </div>
        </div>
    )
}

export default Modal



