import React from "react";
import js from "../../../assets/images/js.png"


const TechImage = () => {
    return(
        <div className="Technologi container-fluid">
            <div className="row ">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <img src={js} alt="js"/>
                </div>
            </div>
        </div>
    )
}

export default TechImage