import React from "react"
import "../../../sass/pages/adaptiveVac.sass"
import HeadVacancies from "../../Mains/Vacancies/VacanciesList/VacancieFront/HeadVacancies";
import BodyVacFront from "./BodyVacFront";

const VacanciesList = (props) => {

    let blockImg = props.vacancies.state.blockIm.map( i => <div className="row flex-wrap vacancies-dev">
        <div className={i.col } >
            <img src={i.src} alt="ill" />
        </div>
        <div className={i.coles} key={i.id}>
            <HeadVacancies vacancies={props.vacancies} name={i.name} list={i.list}/>
            <BodyVacFront vacation={props.vacancies} key={i.id} message={i.message} least={i.least} tool={i.tool} know={i.know} exp={i.exp} gup={i.gup} und={i.und} plus={i.plus}/>
        </div></div>)
    return (

        <div className="dbs">
            {blockImg}
        </div>
    )
}
export default VacanciesList