import React from "react";
import {Col, Row} from "react-bootstrap";



const TabThird = () => {

    return(
        <Row className="justify-content-center pt-4">
            <h3 className="">Business services</h3>
            <Col md={4} sm={4} className={"col-6"} >
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">Managed IT Services</li>
                    <li className="whiteFiveSubtitle3">Cloud Services</li>
                    <li className="whiteFiveSubtitle3">Data Storage And Management</li>
                </ul>

            </Col>
            <Col md={4} sm={4} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">IT Consulting & Strategy</li>
                    <li className="whiteFiveSubtitle3">IT Services Management</li>
                    <li className="whiteFiveSubtitle3">Data Backup Services</li>
                </ul>

            </Col>
            <Col md={4} sm={4} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">24/7 Help & Suport Desk</li>
                    <li className="whiteFiveSubtitle3">Web & Mobile Solutions</li>
                    <li className="whiteFiveSubtitle3">Email Marketing Services</li>
                </ul>

            </Col>
        </Row>
    )
}
export default TabThird