import React from "react";
import HistoryBody from "./HistoryBody/HistoryBody";
import "../../../sass/aboutPage/histoty.sass"



const HistoryPage = () => {
    return(
        <div className="History">
            <div className="container-fluid">
                <HistoryBody/>
            </div>
        </div>
    )
}
export default HistoryPage