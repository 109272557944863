import React from "react";
import "../../../sass/base/_base.sass"
import bkffuLogo from "../../../assets/images/bkffu_logo.svg"
import bkffu from "../../../assets/images/bkffu_web.png"
import bkffuTwo from "../../../assets/images/bkffu_tablet.png"
import agroThree from "../../../assets/images/bkk.png"
import "../../../sass/Portfolio/portBkff.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import "../../../sass/Portfolio/adaptivePage/adaptiveAgro.sass"
import {useTranslation} from "react-i18next"


const PortfolioPageBKKFU = () => {
    const { t } = useTranslation()
    return (
        <section className="content">
            <div className="PortfolioPageBKKFU container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-bkff">
                        <img src={bkffuLogo} alt="bkff logo"/>
                        <p className="whiteSubtitle1">{t('promotion.10')}</p>
                        <p className="whiteSixMenu">{t('preserving.10')}</p>
                        <p className="whiteSixMenu">{t('bouts.10')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center first-image">
                        <img src={bkffu} alt="bkff-webimage"/>
                    </div>
                </div>
                <div className="row product align-items-center image-bkff-tablet">
                    <div className="col-6 first-tab">
                        <img src={bkffuTwo} alt="bkff-tabletimage"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSixMenu">{t('open.10')}</p>
                        <p className="whiteSixMenu">{t('judges.10')}</p>
                        <p className="whiteSixMenu">{t('strives.10')}</p>
                        <img src={bkffuTwo} alt="bkff-tabletimage" className="image-agros"/>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('website.10')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('broadcast.10')}</li>
                            <li className="whiteSevenBody1">{t('past.10')}</li>
                            <li className="whiteSevenBody1">{t('each.10')}</li>
                            <li className="whiteSevenBody1">{t('fighter.10')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center image-bkff-mobile secondary-image ">
                        <img src={agroThree} alt="bkff-mobileimage"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageBKKFU