import React from "react";
import TechBack from "./TechnologiesBody/TechBack";
import TechnoFriend from "./TechnologiesBody/Technoriend";
import "../../sass/base/fonts.sass"
import TechImage from "./TechnologiesBody/TechImage";
import "../../sass/aboutPage/adaptiveTech.sass"




const Technologies = () => {
    return (
        <div className="content n-techno tab-body">
            <h2 className="whiteTwo text-center">Technologies</h2>
            <p className="whiteSubtitle2 text-center">Customer friendly it service provider</p>
            <TechnoFriend/>
            <TechBack/>
            <TechImage/>
        </div>
    )
}

export default Technologies