import React from 'react'
import AboutUsPage from "./AboutUsPage";
import BenefistPage from "./Benefist/BenefistPage";
import HistoryPage from "./History/HistoryPage";
import OurTeamPage from "./OurTeam/OurTeamPage";
import "../../sass/base/fonts.sass"
import {useTranslation} from "react-i18next";

export const About = () => {
    const { t } = useTranslation();

    return (
        <section id="part1" className="content" >
            <AboutUsPage />
            <h2 className="whiteTwo text-center">{t('benefit.1')}</h2>
            <BenefistPage/>
            <HistoryPage/>
            <OurTeamPage/>
        </section>
    )
}