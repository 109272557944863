import React from "react"
import ContactsConnect from "./ContactsBody/ContactsConnect"
import "../../sass/ContactsStyle/Contacts.sass"
import "../../sass/base/fonts.sass"
import {useTranslation} from "react-i18next"
import {Request} from "../Mains/Request/Request"

const Contacts = () => {
    const { t } = useTranslation();
    return (
        <section className="content Vacations">
            <div className=" container-fluid">
                <div className="Content row">
                    <h1 className="whiteOne text-center">{t('Conta.1')}</h1>
                    <p className="whiteSubtitle2 text-center">{t('are.10')}</p>
                    <ContactsConnect/>
                </div>
            </div>
            <Request/>
        </section>
    )
}
export default Contacts