import React from "react";
import "../../../sass/base/fonts.sass"
import "../../../sass/aboutPage/benefist.sass"
import flexibility from "../../../assets/images/cvcv.svg"
import cooperation from "../../../assets/images/cooperation.svg"
import pragmatism from "../../../assets/images/pragmatism.svg"
import humanity from "../../../assets/images/comunity.svg"
import "../../../sass/aboutPage/adaptiveAbout.sass"
import {useTranslation} from "react-i18next";



const BenefistPage = () => {
    const { t } = useTranslation();
    return(
        <div className="Benefits">
            <div className="container-fluid">
                <div className="row benefits-body align-item-baseline">
                    <div className="col-xxl-3 col-md-6 col-sm-12  ">
                        <div className="d-flex flex-column benefits-item align-items-center">
                            <img src={flexibility} alt="flexibility" className={"mb-3"}/>
                            <div className="benefits-text d-flex flex-column align-items-center justify-content-center">
                                <p className="whiteSubtitle1 ">{t('Flexibility.3')}</p>
                                <p className="benefits-title ">{t('transparent.3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6 col-sm-12  ben">
                        <div className="d-flex flex-column align-items-center">
                            <img src={pragmatism} alt="pragmatism" className={"mb-3"}/>
                            <div className="benefits-text d-flex flex-column align-items-center justify-content-center">
                                <p className="whiteSubtitle1 ">{t('Pragmatism.3')}</p>
                                <p className="benefits-title ">{t('provides.3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6 col-sm-12 ben">
                        <div className="d-flex flex-column align-items-center ben-item">
                            <img src={humanity} alt="humanity" className={"mb-3"}/>
                            <div className="benefits-text d-flex flex-column align-items-center justify-content-center">
                                <p className="whiteSubtitle1">{t('Humanity.3')}</p>
                                <p className="benefits-title ">{t('provide.4')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-6 col-sm-12  ben ">
                        <div className="d-flex flex-column align-items-center benefits-item">
                            <img src={cooperation} alt="cooperation" className={"mb-3"}/>
                            <div className="benefits-text  d-flex flex-column align-items-center justify-content-center">
                                <p className="whiteSubtitle1 ">{t('Cooperation.3')}</p>
                                <p className="benefits-title ">{t('teams.3')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BenefistPage