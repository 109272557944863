import React from "react";
import TabContainers from "./TabBody/TabContainer";
import "../../../sass/aboutPage/adaptiveTech.sass"



const TechnoFriend = () => {
    return (
        <section className={'tab-body'}>
            <div className="TechnoFriend tab-body">
                <TabContainers/>
            </div>
        </section>
    )
}

export default TechnoFriend