import React from 'react'
import { useTranslation } from 'react-i18next';
import "../../sass/base/fonts.sass"
import "../../sass/pages/Technologies.sass"


export const Techno = () => {
    const { t } = useTranslation();

    return (
        <section className="Techno">
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-center">
                        <h2 className="whiteTwo">{t('Techo.4')}</h2>
                        <p className="whiteSubtitle2">{t('Customer.4')}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}