import React from "react"
import "../../sass/pages/Portfolio.sass"
import "../../sass/base/_base.sass"
import "../../sass/base/fonts.sass"
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup'


export const Portfolio = () => {
    const { t } = useTranslation();
    const [focus, setFocus] = React.useState(false);
    return (
        <section className="Portfolio">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 col-sm-12 ">
                        <h2 className="whiteTwo">{t('about.2')}</h2>

                        <p className="whiteSevenBody1">{t('part.2')}</p>
                        <p className="whiteSevenBody1">{t('part.3')}</p>
                        <p className="whiteSevenBody1">{t('part.4')}</p>}

                        <div className="link-bor">
                            <NavLink to={'/about'} className="bor">{t('more.2')}</NavLink>
                        </div>
                    </div>
                    <div className="col-lg-7 col-10 mx-auto achievements">
                        <div className="row flex-wrap justify-content-around">
                            <div className="col-sm-5  text-center block-port bottom-block d-flex flex-column justify-content-center text-center">
                                <div>
                                    <CountUp start={focus ? 0 : null} end={52} redraw={true}>
                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) { setFocus(true); }
                                            }}>
                                                <span ref={countUpRef} className={'block-company'}/>
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </div>
                                <p className="whiteSubtitle1">{t('prototypes.2')}</p>
                                <p className="body2">{t('ground.2')}</p>
                            </div>
                            <div className="col-sm-5 text-center block-port bottom-block d-flex flex-column justify-content-center text-center">
                                <CountUp start={focus ? 0 : null} end={143} redraw={true} suffix={'%'}>
                                    {({ countUpRef }) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }}>
                                            <span ref={countUpRef} className={'block-company'}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <p className="whiteSubtitle1">{t('conversion.2')}</p>
                                <p className="body2">{t('streaming.2')}</p>
                            </div>
                            <div className="col-sm-5  text-center block-port  d-flex flex-column justify-content-center text-center">
                                <CountUp start={focus ? 0 : null} end={100} redraw={true} suffix={'+'}>
                                    {({ countUpRef }) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }}>
                                            <span ref={countUpRef} className={'block-company'}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <p className="whiteSubtitle1">{t('wallets.2')}</p>
                                <p className="body2">{t('application.2')}</p>
                            </div>
                            <div className="col-sm-5 text-center block-port d-flex flex-column justify-content-center text-center">
                                <CountUp start={focus ? 0 : null} end={27} redraw={true} >
                                    {({ countUpRef }) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }}>
                                            <span ref={countUpRef} className={'block-company'}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <p className="whiteSubtitle1">{t('projects.2')}</p>
                                <p className="body2">{t('include.2')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}