import React from "react";
import "../../../../../sass/base/fonts.sass"


const HeadVacancies = (props) => {

    return (
        <div className="row front">
            <div className="col-md-6 col-sm-12 fro">
                <p className="whiteSubtitle1">{props.name}</p>
            </div>
            <div className="col-md-6 col-sm-12 items d-flex justify-content-sm-end">
                <ul>
                    <li className="whiteElevenButton1" >{props.list}</li>
                </ul>
            </div>
        </div>
    )
}

export default HeadVacancies