import React from "react";
import {Request} from "../Mains/Request/Request";
import PortfolioProject from "./PortfolioAgro/PortfolioProjectAgro";
import PortfolioPageTriple from "./PortfolioTriple/PortfolioPageTriple";

const PortfolioTriple = () => {
    return (
        <section className="content">
            <PortfolioPageTriple/>
            <PortfolioProject/>
            <Request/>
        </section>
    )
}

export default PortfolioTriple