import React from "react";
import agro from "../../../assets/images/logWeb.svg";
import agroLi from "../../../assets/images/webworld_main.png";
import {useTranslation} from "react-i18next";


const RewiewProjectTwo = () => {

    const { t } = useTranslation()
    return (
        <div className="row rewiew-body">
            <div className="col-lg-6 col-sm-12 clients-review">
                <img src={agroLi} alt="agroline"/>
            </div>
            <div className="col-lg-6 col-sm-12">
                <img src={agro} alt="agroline" className="coin-image"/>
                <p className="whiteSubtitle1">{t('been.2')}
                </p>
                <p className="whiteFiveSubtitle3">{t('innovative.2')}</p>
                <p className="whiteFiveSubtitle3">{t('trade.2')}</p>
                <h3 className="WhiteThree">{t('aimed.2')}</h3>
                <ul className="d-flex flex-column align-items-start">
                    <li className="whiteSevenBody1">{t('prices.2')}</li>
                    <li className="whiteSevenBody1">{t('distributors.2')}
                    </li>
                    <li className="whiteSevenBody1">{t('Trading.2')}
                    </li>
                    <li className="whiteSevenBody1">{t('stages.2')}
                    </li>
                </ul>
                <img src={agroLi} alt="agroline" className="image-review-two"/>
            </div>
        </div>
    )
}

export default RewiewProjectTwo