

export const required = value => {
    if (value) return undefined

    return 'Field is required'
}

export const req = select => {
    if (select) return undefined

    return 'field'
}


export const maxLengthCreator = (maxLength) => (value) => {
    if (value.length > maxLength) return `Max length is ${maxLength} symbols`

    return  undefined
}