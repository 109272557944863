import React from "react";
import "../../../sass/base/fonts.sass"
import "../../../sass/base/fonts.sass"
import youncut from "../../../assets/images/stock_client.png"
import defi from "../../../assets/images/triple_client.png"
import titan from "../../../assets/images/bkk_client.png"
import agro from "../../../assets/images/web_client.png"
import velta from "../../../assets/images/velte_client.png"
import catchCoin from "../../../assets/images/catch_client.png"
import "../../../sass/pages/review.sass"
import {useTranslation} from "react-i18next"


const DigitalProduct = () => {
    const { t } = useTranslation()
    return (
        <section className="Clients clients_part">
            <div className="container-fluid">
                <div className="row flex-wrap justify-content-center">
                    <h3 className="whiteThree text-center">{t('haves.2')}</h3>
                    <div className="col-md-4 col-sm-6 clients-company clients-bottom">
                        <img src={youncut} alt="youncut"/>
                    </div>
                    <div className="col-md-4 col-sm-6 clients-company clients-bottom">
                        <img src={defi} alt="defi"/>
                    </div>
                    <div className="col-md-4 col-sm-6 clients-company clients-bottom">
                        <img src={titan} alt="titan"/>
                    </div>
                    <div className="col-md-4 col-sm-6 clients-company clients-bottom">
                        <img src={agro} alt="agro"/>
                    </div>
                    <div className="col-md-4 col-sm-6 clients-company clients-bottom">
                        <img src={velta} alt="velta"/>
                    </div>
                    <div className="col-md-4 col-sm-6 clients-company clients-bottom">
                        <img src={catchCoin} alt="catchCoin"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DigitalProduct