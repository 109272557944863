import React from 'react'
import {useTranslation} from 'react-i18next';
import "../../sass/pages/Technologies.sass"
import design from "../../assets/images/DESIGN.png"
import webDev from "../../assets/images/WEB DEV ILLUSTR.png"
import apps from "../../assets/images/APPS ILLUSTR.png"
import ux from "../../assets/images/ux.png"
import sw from "../../assets/images/sw eng.png"
import qa from "../../assets/images/QA.png"



export const Technologies = () => {
    const {t} = useTranslation();
    return (
        <section className="Technologies">
            <div className="container-fluid">
                <div className="row sparki">
                    <div className="col-xl-4 col-lg-6 col-sm-6 sparki-item text-center">
                        <div className="box">
                            <img src={design} alt="design"/>
                            <p className="whiteSubtitle1 caption_h2">UI/UX Design</p>
                            <p className="technol-text">{t('UI.5')}</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-6 sparki-item text-center">
                        <div className="box">
                            <img src={webDev} alt="webDev"/>
                            <p className="whiteSubtitle1 caption_h2">Web development</p>
                            <p className="technol-text">{t('WebDev.5')}</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-6 sparki-item text-center">
                        <div className="box">
                            <img src={apps} alt="apps"/>
                            <p className="whiteSubtitle1 caption_h2">Apps development</p>
                            <p className="technol-text">{t('AppsDev.5')}</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-6 sparki-item text-center">
                        <div className="box">
                            <img src={ux} alt="ux"/>
                            <p className="whiteSubtitle1 caption_h2">Blockchain</p>
                            <p className="technol-text">{t('UX.5')}</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-6 sparki-item text-center">
                        <div className="box">
                            <img src={sw} alt="software"/>
                            <p className="whiteSubtitle1 caption_h2">Cloud & DevOps</p>
                            <p className="technol-text">{t('Energing.5')}
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-6 sparki-item text-center">
                       <div className="box">
                           <img src={qa} alt="test"/>
                           <p className="whiteSubtitle1 caption_h2">Software Testing</p>
                           <p className="technol-text">{t('Test.5')}
                           </p>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


