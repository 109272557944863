import React from "react";
import ninj from "../../../../assets/images/ninj.svg"
import ninj1 from "../../../../assets/images/ninj1.svg"
import ninj2 from "../../../../assets/images/ninj2.svg"
import ninj3 from "../../../../assets/images/ninj3.svg"
import ninj4 from "../../../../assets/images/ninj4.svg"
import "../../../../sass/base/fonts.sass"
import "../../../../sass/aboutPage/ourTeam.sass"
import {useTranslation} from "react-i18next";


const TeamBody = () => {
    const { t } = useTranslation();
    return(
        <div className="row flex-wrap justify-content-around">
            <div className="col-md-12 col-sm-10 team-card">
                <img src={ninj} alt="avatar"/>
                <div className="name-text name-text-long">
                    <p className="whiteSubtitle1 text-center">{t('y.3')}</p>
                    <p className="whiteFiveSubtitle3 text-center ">{t('h.3')}</p>
                </div>
            </div>
            <div className="col-md-6 col-sm-10 team-card">
                <img src={ninj1} alt="avatar"/>
                <div className="name-text">
                    <p className="whiteSubtitle1 text-center">{t('a.3')}</p>
                    <p className="whiteFiveSubtitle3 text-center ">{t('f.3')} </p>
                </div>
            </div>
            <div className="col-md-6 col-sm-10 team-card">
                <img src={ninj2} alt="avatar"/>
                <div className="name-text ">
                    <p className="whiteSubtitle1 text-center">{t('n.3')}</p>
                    <p className="whiteFiveSubtitle3 text-center ">{t('back.3')}</p>
                </div>
            </div>
            <div className="col-md-12 col-sm-10 team-card">
                <img src={ninj3} alt="avatar"/>
                <div className="name-text name-text-long">
                    <p className="whiteSubtitle1 text-center">{t('as.3')}</p>
                    <p className="whiteFiveSubtitle3 text-center ">{t('qualitya.3')}</p>
                </div>
            </div>
            <div className="col-md-6 col-sm-10 team-card">
                <img src={ninj3} alt="avatar"/>
                <div className="name-text ">
                    <p className="whiteSubtitle1 text-center">{t('nt.3')}</p>
                    <p className="whiteFiveSubtitle3 text-center ">{t('ss.3')}</p>
                </div>
            </div>
            <div className="col-md-6 col-sm-10 team-card">
                <img src={ninj4} alt="avatar"/>
                <div className="name-text">
                    <p className="whiteSubtitle1 text-center">{t('rk.3')}</p>
                    <p className="whiteFiveSubtitle3 text-center ">{t('fr.3')}</p>
                </div>
            </div>
            <div className="col-md-12 col-sm-10 team-card">
                <img src={ninj2} alt="avatar"/>
                <div className="name-text name-text-long">
                    <p className="whiteSubtitle1 text-center">{t('ya.3')}</p>
                    <p className="whiteFiveSubtitle3 text-center ">{t('ui.3')}</p>
                </div>
            </div>
        </div>
    )
}
export default TeamBody