import React from "react";
import "../../../sass/base/_base.sass"
import workLabLogo from "../../../assets/images/worklab-logo.svg"
import agro from "../../../assets/images/lab_main.png"
import agroThree from "../../../assets/images/lab_mob.png"
import agroTwo from "../../../assets/images/lab_tab.png"
import "../../../sass/Portfolio/portYour.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import "../../../sass/Portfolio/adaptivePage/adaptiveAgro.sass"
import {useTranslation} from "react-i18next"


const PortfolioPageYour = () => {
    const { t } = useTranslation()
    return (
        <section className="content">
            <div className="PortfolioPageYour container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-your">
                        <img src={workLabLogo} alt="worklab logo"/>
                        <p className="whiteSubtitle1">{t('agency.10')}</p>
                        <p className="whiteSixMenu">{t('mobile.10')}</p>
                        <p className="whiteSixMenu">{t('small.10')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-lg-end justify-content-center image-your-w first-image">
                        <img src={agro} alt="agroTwo"/>
                    </div>
                </div>
                <div className="row product align-items-center">
                    <div className="col-6 image-your-tablet first-tab">
                        <img src={agroTwo} alt="agroTwo"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSixMenu">{t('graphic.10')}</p>
                        <p className="whiteSixMenu">{t('inexpensive.10')}</p>
                        <p className="whiteSixMenu">{t('individuals.10')}</p>
                        <img src={agroTwo} alt="agroTwo" className="image-agros"/>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('they.10')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('applications.10')}</li>
                            <li className="whiteSevenBody1">{t('Maintenance.10')}</li>
                            <li className="whiteSevenBody1">SEO</li>
                            <li className="whiteSevenBody1">{t('Migration.10')}</li>
                            <li className="whiteSevenBody1">{t('Commerce.10')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center image-your-mobile secondary-image">
                        <img src={agroThree} alt="agroThree "/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageYour