import React from "react";
import "../../../sass/pages/adaptive.sass"
import "../../../sass/pages/requred.sass"



export const inputName = ( {input, meta, ...props}) => {

    // const hasError = meta.touched && meta.error

    return (
        <div className={"required d-flex formControl"  }>
            <p className="whiteSubtitle1">Name</p>
            <input {...input} {...props} />
        </div>
    )
}
export const inputPhone = ({input, meta, ...props}) => {

    // const hasError = meta.touched && meta.error

    return (
        <div className={"required d-flex formControl" }>
            <p className="whiteSubtitle1">Phone</p>
            <input  {...input} {...props} />
        </div>
    )
}

export const TextArea = ({input, meta, ...props}) => {

    // const hasError = meta.touched && meta.error

    return (
        <div className={"required d-flex formControl "}>
            <p className="whiteSubtitle1">Detail</p>
            <textarea {...input} {...props} />
        </div>
    )
}


// export const selectForm = ({input, meta, ...props}) => {
//
//
//     const options = [
//         { value: 'Development', label: 'Web Development' },
//         { value: 'Design', label: 'Design' },
//         { value: 'Engineering', label: 'System Engineering' },
//         { value: 'Marketing', label: 'Marketing' },
//         { value: 'Mobile', label: 'Mobile App development' },
//     ]
//
//     const hasError = meta.touched && meta.error
//
//     return (
//         <div className={"required d-flex formControl" + (hasError ? "error" : "")}>
//             <p className="whiteSubtitle1">Required services</p>
//             <select id="select_type" options={options} name={"service"} className={"select-form"}>
//                 <option></option>
//                 <option></option>
//                 <option></option>
//             </select>
//         </div>
//     )
// }
