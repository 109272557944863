import React, {useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import TabFirst from "./TabFirst";
import TabThird from "./TabThird";
import TabSecond from "./TabSecond";


export const TabButton = () =>  {
    const [key, setKey] = useState('first')

        return(
            <div className="TechBack d-flex flex-xl-fill flex-lg-fill flex-md-fill flex-xl-column justify-content-between align-items-center">
                <div className="tech-container col-sm-12 col-12">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3 container-fluid"
                    >
                        <Tab eventKey="first" title="Development services" className={"container-fluid mt-5"}>
                            <TabFirst/>
                        </Tab>
                        <Tab eventKey="second" title="Design Services" className={"container-fluid mt-5"}>
                            <TabSecond />
                        </Tab>
                        <Tab eventKey="third" title="Business Services" className={"container-fluid mt-5 "}>
                            <TabThird />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
}



export default TabButton
