import React from "react";
import {Request} from "../Mains/Request/Request";
import PortfolioPageStream from "./PortfolioStream/PortfolioPageStream";
import PortfolioProject from "./PortfolioAgro/PortfolioProjectAgro";

const PortfolioStream = () => {
    return (
        <section className="content">
            <PortfolioPageStream/>
            <PortfolioProject/>
            <Request/>
        </section>
    )
}

export default PortfolioStream