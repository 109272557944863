import React from "react";
import "../../../sass/base/_base.sass"
import agroLogo from "../../../assets/images/living_logo.svg"
import agroLogoP2 from "../../../assets/images/living_logo_p2.png"
import agro from "../../../assets/images/living_web.png"
import agroTwo from "../../../assets/images/living_tab.png"
import agroThree from "../../../assets/images/living_mob.png"
import "../../../sass/Portfolio/portStream.sass"
import "../../../sass/pages/review.sass"
import "../../../sass/base/fonts.sass"
import {useTranslation} from "react-i18next";


const PortfolioPageStream = () => {
    const { t } = useTranslation()
    return (
        <section className="content">
            <div className="PortfolioPageStream container-fluid">
                <div className="row page-body">
                    <div className="col-lg-5 col-sm-12 image-stream">
                        <div className="logo-container">
                            <img className="logo" src={agroLogo} alt="fightliving logo"/>
                            &nbsp;
                            <img className="logo-text" src={agroLogoP2} alt="fightliving logo text"/>
                        </div>
                        <p className="whiteSubtitle1">{t('fightliving_t.11')}</p>
                        <p className="whiteSixMenu">{t('fightliving_p_1.11')}</p>
                        <p className="whiteSixMenu">{t('fightliving_p_2.11')}</p>
                    </div>
                    <div className="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center first-image">
                        <img src={agro} alt="agroTwo"/>
                    </div>
                </div>
                <div className="row product align-items-center">
                    <div className="col-6 first-tab">
                        <img src={agroTwo} alt="agroTwo"/>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <p className="whiteSixMenu">{t('fightliving_p_3.11')}</p>
                        <p className="whiteSixMenu">{t('fightliving_p_4.11')}</p>
                        <p className="whiteSixMenu">{t('fightliving_p_5.11')}</p>
                    </div>
                </div>
                <div className="row product-two">
                    <div className="col-lg-6 col-sm-12">
                        <p className="WhiteThree">{t('fightliving_st.11')}</p>
                        <ul className="d-flex flex-column align-items-start">
                            <li className="whiteSevenBody1">{t('fightliving_li_1.11')}</li>
                            <li className="whiteSevenBody1">{t('fightliving_li_2.11')}</li>
                            <li className="whiteSevenBody1">{t('fightliving_li_3.11')}</li>
                            <li className="whiteSevenBody1">{t('fightliving_li_5.11')}</li>
                            <li className="whiteSevenBody1">{t('fightliving_li_4.11')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex justify-content-center">
                        <img src={agroThree} alt="agroThree"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioPageStream