import React from "react";
import {Request} from "../Mains/Request/Request";
import PortfolioProject from "./PortfolioAgro/PortfolioProjectAgro";
import PortfolioPagePartner from "./PortfolioTriple/PortfolioPagePartner";

const PortfolioPartner = () => {
    return (
        <section className="content">
            <PortfolioPagePartner/>
            <PortfolioProject/>
            <Request/>
        </section>
    )
}

export default PortfolioPartner