import React from 'react'
import { Create } from './Create'
import { Portfolio } from './Portfolio'
import { Request } from './Request/Request'
import { Rewiew } from './Rewiew'
import { Techno } from './Techno'
import { Technologies } from './Technologies'
import Clients from "./Clients/Clients";
import "../../sass/base/_base.sass"



export const MainPage = () => {
    return (
        <section className="content">
            <Create/>
            <Portfolio/>
            <Techno/>
            <Technologies/>
            <Rewiew/>
            <Clients/>
            <Request />
            {/*<Vacancies/>*/}
        </section>
    )
}