import React from "react";
import "../../../sass/base/fonts.sass"
import "../../../sass/Portfolio/portAgro.sass"
import ProjectPort from "../../Portfolio/ProjectPort";
import {useTranslation} from "react-i18next";


const PortfolioProject = () => {

    const { t } = useTranslation()

    return (
        <section className="Another">
            <div className="container-fluid">
                <h2 className="whiteTwo text-center">{t('Another.12')}</h2>
                <ProjectPort/>
            </div>
        </section>
    )
}

export default PortfolioProject