import React from "react";
import VacanciesReact from "./VacanciesReact/VacanciesReact";
import vac from "../../assets/images/illustration fed.png";
import bac from "../../assets/images/illustrMan.png";
import des from "../../assets/images/des.png";



let store = {

    state: {
        blockIm: [
            {id: 1, col: "col-lg-6 col-sm-12 photo-border", src: vac, coles: "col-lg-6 col-sm-12 vacanciesFront", name: 'Front-end developer',
                message: 'We are looking for experienced ReactNative developers who are passionate about solving complex problems and creating high quality mobile apps based on modern technology.',
            list: 'RESTful APIs OAuth Push Notifications',
            least: '3+ years of experience in mobile development',
            tool: '2+ years of experience with React-Native',
            know: 'Experience in downloading applications to stores',
            exp: 'Experience with payments, geolocation, custom designs and animations',
            gup: 'Experience with GraphQL',
            und: 'Knowledge of Typescript and any state management middleware preferably Redux',
            plus: 'Familiarity with native build tools, like XCode, Android Studio ,Gradle'
            },
            {id: 2, col: "col-lg-6 col-sm-12 photo-border", src: vac, coles: "col-lg-6 col-sm-12 vacanciesFront", name: 'Senior UI/UX Designer',
                message: 'SimplexPage is searching for a Senior Digital Product Designer to join our team.',
            list: 'HTML5 CSS3 SASS AJAX JS React Node.js',
            least: '3+ years of web & mobile design',
            tool: 'Solid experience working with Figma, Sketch, Adobe Suite',
            know: 'Webflow / Readymag / Tilda / After Effects / Cinema 4D',
            exp: 'Experience with FinTech / Crypto / NFT products',
            gup: '',
            und: '',
            plus: 'Experience in conducting and/or participating in quality product research (usability testing, user surveys, etc.)'
            },
            {id: 3, col: "col-lg-6 col-sm-12 photo-border", src: bac, coles: "col-lg-6 col-sm-12 vacanciesFront", name: 'Back-end developer',
                message: 'We are looking for a Go Developer to join our team in developing our innovative future metaverse.',
                list: 'Golang SQL REST',
                least: '2+ years experience Go programming language',
                tool: 'Hands-on experience with SQL and noSQL dbs',
                know: 'Experience in building APIs, data modeling',
                gup: 'Experience with major established blockchains',
                und: 'Familiarity with Ethereum code and smart contract development',
                plus: 'Experience working with Docker, AWS'
            },
            {id: 4, col: "col-lg-6 col-sm-12 photo-border", src: des, coles: "col-lg-6 col-sm-12 vacanciesFront", name: 'Middle DevOps',
                message: 'We are looking for a Middle AWS DevOps Engineer with 2+ years of commercial experience to join our successful and stable project',
                list: 'AWS Jenkins Kubernetes',
                least: 'Experience with CI/CD systems: ideally AWS based: Codebuild/Codepipeline/Codedeploy',
                tool: 'Experience with IaC: Clouformation, CDK',
                know: 'Experience with bash, Ansible',
                exp: 'Hands-on experience with Linux environments',
                gup: 'Experience with core AWS services such as EC2, VPC, IAM, S3, Lambda',
                und: '',
                plus: 'Experience with Serverless and Docker; SRE practices understanding; AWS certification '}

        ],
    },

}


const VacanciesPage = () => {

    // const { t } = useTranslation()

    return(
        <section className="content">
            <div className="Vacancies">
                <div className="container-fluid">
                    <VacanciesReact store = {store} />
                </div>
            </div>
        </section>
    )
}
export default VacanciesPage


