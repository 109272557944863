import React from "react"
import {NavLink} from "react-router-dom"
import agroLi from "../../assets/images/on hover.png"
import bkk from "../../assets/images/def.png"
import coris from "../../assets/images/def(1).png"
import titam from "../../assets/images/titan.png"
import stockPoint from "../../assets/images/stockHigh.png"
import catchCoin from "../../assets/images/def(5).png"
import dtreamImage from "../../assets/images/def(4).png"
import webWorld from "../../assets/images/imWeb.png"
import Triple from "../../assets/images/triple_man.png"
import Partner from "../../assets/images/partner_man.png"

let listPage = [

    {id: 1, block: "col-md-6 col-sm-11 project project-lg bulli", way: "/webworld", name: "link-image", click: 'setActive(false)', style: "height: 100%;" , src: webWorld},
    {id: 2, block: "col-md-6 col-sm-11 project  project-lg", way: "/catchcoin", name: "link-image", src: catchCoin},
    {id: 3, block: "col-md-3 col-sm-11 project  project-sm", way: "/worklab", name: "link-image", src: coris},
    {id: 4, block: "col-md-5 col-sm-11 project project-sm",way: "/titanera", name: "link-image", src: titam},
    {id: 5, block: "col-md-4 col-sm-11 project project-sm",way: "/fightliving", name: "link-image", src: dtreamImage},
    {id: 6, block: "col-md-6 col-sm-11 project project-lg",way: "/stockpoint", name: "link-image", src: stockPoint},
    {id: 7, block: "col-md-6 col-sm-11 project project-lg",way: "/tripleex", name: "link-image", src: Triple},
    {id: 8, block: "col-md-4 col-sm-11 project project-sm",way: "/agroline", name: "link-image", src: agroLi},
    {id: 9, block: "col-md-4 col-sm-11 project project-sm", way: "/bkkfu", name: "link-image", src: bkk},
    {id: 10, block: "col-md-4 col-sm-11 project project-sm bulli", way: "/tripleextoken", name: "link-image", src: Partner},

]
// onClick={e => e.stopPropagation()} часть картинки
const linkImage = listPage.map( d => <div className={d.block} onClick={() => d.click} key={d.id}><NavLink to={d.way} className={d.name} onClick={() => d.click} key={d.id}><img src={d.src} className={"port-img"}  alt=""/></NavLink></div>)

const ProjectPort = () => {
    return (
        <div className="row flex-wrap justify-content-center">
            {linkImage}
        </div>
    )
}

export default ProjectPort