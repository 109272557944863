import React from "react";
import "../../../sass/base/fonts.sass"
import "../../../sass/aboutPage/technolodies.sass"

const TechBack = () => {
    return (
        <div className="TechBack TechBack-container container-fluid">
            <div className="row flex-wrap tech-back-item">
                <h2 className="whiteTwo">Tech Stack</h2>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10 ">
                    <div className={"tabs-link tabs-small d-flex flex-column align-items-center justify-content-center "}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">IOS</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">XCode</p>
                                <p className="whiteEightButton2">Applyzer</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">AppCode</p>
                                <p className="whiteEightButton2">RxSwift</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Testflight</p>
                                <p className="whiteEightButton2">Flutter</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10 ">
                    <div className={"tabs-link tabs-small d-flex flex-column align-items-center justify-content-center "}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Android</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Android Studio</p>
                                <p className="whiteEightButton2">Xamarin</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Flutter</p>
                                <p className="whiteEightButton2">Kotlin</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10 ">
                    <div className={"tabs-link tabs-small d-flex flex-column align-items-center justify-content-center "}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Blockchain</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">DApps</p>
                                <p className="whiteEightButton2">ICO</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Smart Contracts</p>
                                <p className="whiteEightButton2">Token Creation</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">NFT</p>
                                <p className="whiteEightButton2">DeFi</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10 ">
                    <div className={"tabs-link tabs-small d-flex flex-column align-items-center justify-content-center "}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Web Technology</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Laravel</p>
                                <p className="whiteEightButton2">Vue JS</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Angular JS</p>
                                <p className="whiteEightButton2">React JS</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Continuous Integration</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Jenkins</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">CircleCI</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">GitLab CI/CD</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">CMS / Online Marketplace</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">WordPress</p>
                                <p className="whiteEightButton2">Shopify</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Joomla</p>
                                <p className="whiteEightButton2">OpenCart</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Drupal</p>
                                <p className="whiteEightButton2">Tilda</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">System Admin/DevOps</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Docker</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Kubernetes</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Payment Integration</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">PayPal</p>
                                <p className="whiteEightButton2">Giropay</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Visa</p>
                                <p className="whiteEightButton2">MasterCard</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Databases</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">MySQL</p>
                                <p className="whiteEightButton2">MongoDB</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">PostgresSQL</p>
                                <p className="whiteEightButton2">NoSQL</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Cloud Tools</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Amazon Web Services</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Google Cloud</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Microsoft Azure</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Designing Tools</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Sketch</p>
                                <p className="whiteEightButton2">Figma</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Adobe Photoshop</p>
                                <p className="whiteEightButton2">Adobe Illustrator</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                    <div className={"tabs-link tabs-high text-center d-flex flex-column align-items-center justify-content-center"}>
                        <h3 className="whiteSubtitle1 text-center mb-lg-3">Server Side Scripting</h3>
                        <div className="d-flex">
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">PHP</p>
                                <p className="whiteEightButton2">Python</p>
                            </div>
                            <div className="text-center px-3">
                                <p className="whiteEightButton2">Node JS</p>
                                <p className="whiteEightButton2">.Net</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechBack