import React, {useState} from "react";
import "../../../sass/base/fonts.sass"
import ProjectPort from "../ProjectPort";
import {useTranslation} from "react-i18next";


const LastProject = (props) => {
    const {t} = useTranslation()
    const [modalActive, setModalActive] = useState(false)

    return (
        <section className="LastProject">
            <div className="container-fluid">
                <h2 className="whiteTwo text-center">{t('Last.2')}</h2>
                <ProjectPort active={modalActive} setActive={setModalActive}/>
            </div>
        </section>
    )
}
export default LastProject