import React from "react";
import people from "../../assets/images/sskob2.svg"
import skob from "../../assets/images/skob.svg"
import illu from "../../assets/images/illu.png"
import "../../sass/base/fonts.sass"
import "../../sass/aboutPage/about.sass"
import "../../sass/aboutPage/adaptiveAbout.sass"
import {useTranslation} from "react-i18next";


const AboutUsPage = () => {
    const { t } = useTranslation();
    return (
        <div className="About">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="about-text ">
                            <img src={people} alt="skob" className="text-img"/>
                            <img src={skob} alt="skob" className="text-imgtwo"/>
                            <p className="whiteSubtitle2">{t('number.3')}</p>
                        </div>
                        <div className="about-comment ">
                            <p className="whiteEightButton2"><strong>{t('kol.3')}</strong></p>
                            <p className="whiteElevenButton1">CEO</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                        <div className="about-img">
                            <img src={illu} alt="illustration"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutUsPage