import React from "react";
import {Col, TabContainer} from "react-bootstrap";

import TabButton from "./TabButton";


const TabContainers = () => {
    return(
        <TabContainer id="left-tabs-example" defaultActiveKey="first">
            <div className="row justify-content-between">
                <Col sm={12} >
                    <div className="tech-section d-flex justify-content-xl-center">
                        <TabButton />
                    </div>
                    {/*<TabContent className="tab-body">*/}
                    {/*    <TabPane eventKey="first">*/}
                    {/*        <TabFirst/>*/}
                    {/*    </TabPane>*/}
                    {/*    <TabPane eventKey="second">*/}
                    {/*        <TabSecond/>*/}
                    {/*    </TabPane>*/}
                    {/*    <TabPane eventKey="third">*/}
                    {/*        <TabThird/>*/}
                    {/*    </TabPane>*/}
                    {/*</TabContent>*/}
                </Col>
                {/*<Col xl={4} sm={12} className="tech-section d-flex justify-content-xl-center">*/}
                {/*    */}
                {/*</Col>*/}
            </div>
        </TabContainer>
    )
}

export default TabContainers