import React from "react"
import PortfolioDemo from "./PortfolioBody/PortfolioDemo"
import "../../sass/Portfolio/adaptivePort.sass"
import "../../sass/base/_base.sass"
import DigitalProduct from "./PortfolioBody/DigitalProduct";
import LastProject from "./PortfolioBody/LastProject";



const Portfolio = () => {
    return (
        <section className="content">
            <div className="PortfolioPage">
                <div className="container-fluid">
                    <PortfolioDemo/>
                </div>
                <DigitalProduct/>
                <LastProject/>
            </div>
        </section>
    )

}

export default Portfolio