import React from "react";
import {Col, Row} from "react-bootstrap";
import "../../../../sass/aboutPage/adaptiveTech.sass"



const TabFirst = () => {

    return(
        <Row className="justify-content-between pt-4">
            <h3 className="">Development services</h3>
            <Col xl={4} lg={4} sm={4} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">Product Consulting</li>
                    <li className="whiteFiveSubtitle3">System Architecture</li>
                    <li className="whiteFiveSubtitle3">Custom Software Development</li>
                    <li className="whiteFiveSubtitle3">Mobile Apps</li>
                    <li className="whiteFiveSubtitle3">Project Management</li>
                </ul>
            </Col>
            <Col xl={3} lg={3} sm={4} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">DevOps</li>
                    <li className="whiteFiveSubtitle3">Custom CMS</li>
                    <li className="whiteFiveSubtitle3">Platform Migration</li>
                    <li className="whiteFiveSubtitle3">Data Migration</li>
                    <li className="whiteFiveSubtitle3">System Maintenance</li>
                </ul>
            </Col>
            <Col xl={5} lg={4} sm={4} className={"col-6"}>
                <ul className={"tech-list"}>
                    <li className="whiteFiveSubtitle3">Services Integration</li>
                    <li className="whiteFiveSubtitle3">System Auditing</li>
                    <li className="whiteFiveSubtitle3">Automation</li>
                    <li className="whiteFiveSubtitle3">Comprehensive Testing & QA</li>
                </ul>
            </Col>
        </Row>
    )
}

export default TabFirst