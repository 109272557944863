import React from "react";
import "../../../sass/Clients/clients.sass"
import youncut from "../../../assets/images/stock_client.png"
import defi from "../../../assets/images/triple_client.png"
import titan from "../../../assets/images/bkk_client.png"
import agro from "../../../assets/images/web_client.png"
import velta from "../../../assets/images/velte_client.png"
import catchCoin from "../../../assets/images/catch_client.png"


const Clients = () => {
    return (
        <section className="Clients">
            <div className="container-fluid">
                <div className="row flex-wrap justify-content-center">
                    <div className="col-4 clients-company">
                        <img src={youncut} alt="youncut"/>
                    </div>
                    <div className="col-4 clients-company">
                        <img src={defi} alt="defi"/>
                    </div>
                    <div className="col-4 clients-company">
                        <img src={titan} alt="titan"/>
                    </div>
                    <div className="col-4 clients-company clients-bottom">
                        <img src={agro} alt="agro"/>
                    </div>
                    <div className="col-4 clients-company clients-bottom">
                        <img src={velta} alt="velta"/>
                    </div>
                    <div className="col-4 clients-company clients-bottom">
                        <img src={catchCoin} alt="catchCoin"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Clients